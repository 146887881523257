export const SET_ADMIN = "SET_ADMIN";
export const ADD_ADMIN_LOADING = "ADD_ADMIN_LOADING";
export const ADD_ADMIN_SUCCESS = "ADD_ADMIN_SUCCESS";
export const ADD_ADMIN_ERROR = "ADD_ADMIN_ERROR";
export const SET_APPLICATION_STATUSES = "SET_APPLICATION_STATUSES";

export default {
  SET_ADMIN,
  ADD_ADMIN_LOADING,
  ADD_ADMIN_SUCCESS,
  ADD_ADMIN_ERROR,
  SET_APPLICATION_STATUSES,
};
