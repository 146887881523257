<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 74.225 74">
    <defs>
      <linearGradient
        id="a"
        x2="1.174"
        y2="1.213"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#3674b9" />
        <stop offset=".492" stop-color="#3674b9" />
        <stop offset="1" stop-color="#fde68a" />
      </linearGradient>
    </defs>
    <path
      data-name="Intersection 84"
      d="M-9799.331-1774a7.85 7.85 0 01-7.562-7.833v-9.281l-1.626 1.481-3.9-4.269 22.522-20.54 22.525 20.54-3.9 4.269-1.627-1.481v9.281a7.851 7.851 0 01-7.565 7.833zm-1.778-22.389v14.554a2.06 2.06 0 002.057 2.059h18.315a2.063 2.063 0 002.06-2.059v-14.554l-11.218-10.226zm-12.658 20.884a122.144 122.144 0 01-13.74-13.427c-9.349-10.727-14.087-20.355-14.087-28.618v-.821a29.67 29.67 0 0129.646-29.629 29.672 29.672 0 0129.646 29.629v.821c0 .692-.037 1.4-.1 2.111l-5.8-5.287a23.889 23.889 0 00-23.744-21.492 23.882 23.882 0 00-23.862 23.848v.821c0 13.963 18 30.865 23.862 36v7.514zm19.606-10.2v-8.673h8.678v8.673zm-30.079-32.667a12.3 12.3 0 0112.292-12.285 12.3 12.3 0 0112.292 12.285 12.3 12.3 0 01-12.292 12.287 12.3 12.3 0 01-12.292-12.285zm5.784 0a6.514 6.514 0 006.508 6.506 6.514 6.514 0 006.508-6.506 6.512 6.512 0 00-6.508-6.5 6.512 6.512 0 00-6.508 6.502z"
      transform="translate(9841.595 1847.999)"
      fill="url(#a)"
    />
  </svg>
</template>
