export default {
    state: {
        applicantId: "",
        applicantTypeId: "",
        education: {
            departmentId: "",
            institutionId: "",
        },
        regionId: "",
        zoneId: "",
        residenceWoredaId: "",
        professionalTypeIds: [],
        occupationTypeId: "",
        nativeLanguageId: "",
        expertLevelId: "",
        educationalLevelId: "",
        paymentSlip: null,
        otherEducationalInstitution: "",
        otherProfessionalType: "",
        passport: "",
        healthExamCert: "",
        englishLanguage: "",
        professionalDocCertificate: "",
        professionalDocCertificate2: "",
        professionalDocCertificate3: "",
        professionalDocCertificate4: "",
        professionalDocCertificate5: "",
        professionalDocDiploma: "",
        professionalDocDiploma2: "",
        professionalDocDiploma3: "",
        professionalDocDiploma4: "",
        professionalDocDiploma5: "",
        professionalDocTranscript: "",
        professionalDocTranscript2: "",
        professionalDocTranscript3: "",
        professionalDocTranscript4: "",
        professionalDocTranscript5: "",
        proCertificate: "",
        proDiploma: "",
        proTranscript: "",
        eduEighthGrade: "",
        eduTenthGrade: "",
        eduTwelvethGrade: "",
        eduTranscript1: "",
        eduTranscript2: "",
        herqa: "",
        supportLetter: "",
        coc: "",
        coc2: "",
        coc3: "",
        educationalDocuments: [],
        workExperience: "",
        workExperience2: "",
        serviceFee: "",
        payroll: "",
        renewedLicense: "",
        professionalLicense: "",
        professionalLicense2: "",
        degree: "",
        transcript: "",
        transcript2: "",
        diploma: "",
        masters: "",
        phd: "",
        mastersTranscript: "",
        mastersTranscript2: "",
        phdTranscript: "",
        phdTranscript2: "",
        renewedLicenseOfHealthFacility: "",
        requestLetterFromHiringHealthFacility: "",
        buttons: [],
        applicationId: 0,
        documentSpec: [],
        draft: "",
        declinedFields: [],
        acceptedFields: [],
        remark: "",
    },
};