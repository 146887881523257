<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66.945 65.998">
    <defs>
      <linearGradient
        id="a"
        x2="1.235"
        y2="1.246"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#3674b9" />
        <stop offset=".492" stop-color="#3674b9" />
        <stop offset="1" stop-color="#fde68a" />
      </linearGradient>
    </defs>
    <path
      data-name="Intersection 82"
      d="M-6975.057-3737.267a7.493 7.493 0 01-7.5-7.479v-51.774a7.481 7.481 0 014.256-6.744h58.431a7.482 7.482 0 014.258 6.744v51.774a7.5 7.5 0 01-7.5 7.479zm-4.714-59.253v51.774a4.7 4.7 0 004.714 4.7h51.939a4.7 4.7 0 004.714-4.7v-51.774a4.7 4.7 0 00-4.714-4.7h-51.939a4.7 4.7 0 00-4.714 4.699zm26.137 29.669c0-6.66 3.753-10.441 8.076-10.441a5.791 5.791 0 015.314 2.767h.083v-11.4h3.474v23.8c0 1.626.082 3.671.167 4.976h-3.083l-.209-3.2h-.11a6.5 6.5 0 01-5.971 3.532c-4.407.003-7.742-3.904-7.742-10.035zm3.46-.084c0 4.046 1.744 7.4 5.216 7.4 1.981 0 3.934-1.305 4.63-4.044a5.179 5.179 0 00.167-1.474v-3.948a8.63 8.63 0 00-.125-1.476 4.783 4.783 0 00-4.59-4.044c-3.444-.005-5.299 3.372-5.299 7.585zm-11.465 9.787v-27.526h3.488v27.526z"
      transform="translate(6982.56 3803.265)"
      fill="url(#a)"
    />
  </svg>
</template>
