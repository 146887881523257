export default {
  state: {
    applicantId: "",
    applicantTypeId: "",
    education: {
      departmentId: "",
      institutionId: "",
    },
    regionId: "",
    zoneId: "",
    residenceWoredaId: "",
    professionalTypeID: "",
    expertLevelId: "",
    licenseCopy: "",
    serviceFee: "",
    verificationLetter: "",
    buttons: [],
    applicationId: 0,
    documentSpec: [],
    draft: "",
    declinedFields: [],
    acceptedFields: [],
    remark: "",
  },
};
