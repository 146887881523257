export const SET_LICENSE = "SET_LICENSE";
export const SET_RENEWAL_HEALTH_EXAM_CERT = "SET_RENEWAL_HEALTH_EXAM_CERT";
export const SET_RENEWAL_LETTER = "SET_RENEWAL_LETTER";
export const SET_RENEWAL_WORK_EXPERIENCE = "SET_RENEWAL_WORK_EXPERIENCE";
export const SET_RENEWAL_WORK_EXPERIENCE2 = "SET_RENEWAL_WORK_EXPERIENCE2";
export const SET_RENEWAL_SERVICE_FEE = "SET_RENEWAL_SERVICE_FEE";
export const SET_RENEWAL_CPD = "SET_RENEWAL_CPD";
export const SET_RENEWAL_CPD2 = "SET_RENEWAL_CPD2";
export const SET_RENEWAL_CPD3 = "SET_RENEWAL_CPD3";
export const SET_RENEWAL_CPD4 = "SET_RENEWAL_CPD4";
export const SET_RENEWAL_CPD5 = "SET_RENEWAL_CPD5";
export const SET_PREVIOUS_LICEENSE = "SET_PREVIOUS_LICEENSE";
export const SET_BUTTONS = "SET_BUTTONS";
export const SET_APPLICATION_ID = "SET_APPLICATION_ID";
export const SET_DOCUMENT_SPEC = "SET_DOCUMENT_SPEC";
export const SET_DRAFT = "SET_DRAFT";
export const SET_DECLINED_FIELDS = "SET_DECLINED_FIELDS";
export const SET_ACCEPTED_FIELDS = "SET_ACCEPTED_FIELDS";
export const SET_REMARK = "SET_REMARK";
export const SET_PROFESSIONAL_DOCUMENT = "SET_PROFESSIONAL_DOCUMENT";
export const SET_PAYROLL = "SET_PAYROLL";
export const SET_CERTIFICATE = "SET_CERTIFICATE";
export const SET_DIPLOMA = "SET_DIPLOMA";
export const SET_TRANSCRIPT = "SET_TRANSCRIPT";

export const SET_PASSPORT = "SET_PASSPORT";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_HERQA = "SET_HERQA";
export const SET_LETTER_FROM_ORG = "SET_LETTER_FROM_ORG";
export const SET_PROFESSIONAL_LICENSE = "SET_PROFESSIONAL_LICENSE";
export const SET_RENEWED_LICENSE = "SET_RENEWED_LICENSE";
export const SET_COC = "SET_COC";
export const SET_DEGREE = "SET_DEGREE";
export const SET_EDUCATIONAL_DOCUMENT = "SET_EDUCATIONAL_DOCUMENT";
export const SET_EDU_EIGHTH = "SET_EDU_EIGHTH";
export const SET_EDU_TENTH = "SET_EDU_TENTH";
export const SET_EDU_TWELVETH = "SET_EDU_TWELVETH";
export const SET_EDU_TRANSCRIPT1 = "SET_EDU_TRANSCRIPT1";
export const SET_EDU_TRANSCRIPT2 = "SET_EDU_TRANSCRIPT2";
export const SET_SUPPORT_LETTER = "SET_SUPPORT_LETTER";
export const SET_PRO_CERTIFICATE = "SET_PRO_CERTIFICATE";
export const SET_PRO_DIPLOMA = "SET_PRO_DIPLOMA";
export const SET_PRO_TRANSCRIPT = "SET_PRO_TRANSCRIPT";

export const SET_MASTERS = "SET_MASTERS";
export const SET_MASTERS_TRANSCRIPT = "SET_MASTERS_TRANSCRIPT";
export const SET_PHD = "SET_PHD";
export const SET_PHD_TRANSCRIPT = "SET_PHD_TRANSCRIPT";
export const SET_RENEWED_LICENSE_OF_HEALTH_FACILITY =
    "SET_RENEWED_LICENSE_OF_HEALTH_FACILITY";

export default {
    SET_LICENSE,
    SET_RENEWAL_HEALTH_EXAM_CERT,
    SET_RENEWAL_LETTER,
    SET_RENEWAL_WORK_EXPERIENCE,
    SET_RENEWAL_WORK_EXPERIENCE2,
    SET_RENEWAL_SERVICE_FEE,
    SET_PROFESSIONAL_DOCUMENT,
    SET_RENEWAL_CPD,
    SET_RENEWAL_CPD2,
    SET_RENEWAL_CPD3,
    SET_RENEWAL_CPD4,
    SET_RENEWAL_CPD5,
    SET_PREVIOUS_LICEENSE,
    SET_CERTIFICATE,
    SET_DIPLOMA,
    SET_TRANSCRIPT,
    SET_PAYROLL,
    SET_BUTTONS,
    SET_APPLICATION_ID,
    SET_DOCUMENT_SPEC,
    SET_DRAFT,
    SET_DECLINED_FIELDS,
    SET_ACCEPTED_FIELDS,
    SET_REMARK,
    SET_PASSPORT,
    SET_LANGUAGE,
    SET_HERQA,
    SET_LETTER_FROM_ORG,
    SET_PROFESSIONAL_LICENSE,
    SET_RENEWED_LICENSE,
    SET_COC,
    SET_DEGREE,
    SET_EDUCATIONAL_DOCUMENT,
    SET_EDU_EIGHTH,
    SET_EDU_TENTH,
    SET_EDU_TWELVETH,
    SET_EDU_TRANSCRIPT1,
    SET_EDU_TRANSCRIPT2,
    SET_SUPPORT_LETTER,
    SET_PRO_CERTIFICATE,
    SET_PRO_DIPLOMA,
    SET_PRO_TRANSCRIPT,

    SET_MASTERS,
    SET_MASTERS_TRANSCRIPT,
    SET_PHD,
    SET_PHD_TRANSCRIPT,
    SET_RENEWED_LICENSE_OF_HEALTH_FACILITY,
};