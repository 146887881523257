export const SET_RENEWAL_UNASSIGNED = "SET_RENEWAL_UNASSIGNED";
export const SET_RENEWAL_UNASSIGNED_SEARCHED =
  "SET_RENEWAL_UNASSIGNED_SEARCHED";

export const SET_RENEWAL_UNFINISHED = "SET_RENEWAL_UNFINISHED";
export const SET_RENEWAL_UNFINISHED_SEARCHED =
  "SET_RENEWAL_UNFINISHED_SEARCHED";
export const SET_RENEWAL_OTHERS_UNFINISHED = "SET_RENEWAL_OTHERS_UNFINISHED";
export const SET_RENEWAL_OTHERS_UNFINISHED_SEARCHED =
  "SET_RENEWAL_OTHERS_UNFINISHED_SEARCHED";

export const SET_RENEWAL_ASSIGNED_TO_YOU = "SET_RENEWAL_ASSIGNED_TO_YOU";
export const SET_RENEWAL_ASSIGNED_TO_YOU_SEARCHED =
  "SET_RENEWAL_ASSIGNED_TO_YOU_SEARCHED";
export const SET_RENEWAL_ASSIGNED_TO_OTHERS = "SET_RENEWAL_ASSIGNED_TO_OTHERS";
export const SET_RENEWAL_ASSIGNED_TO_OTHERS_SEARCHED =
  "SET_RENEWAL_ASSIGNED_TO_OTHERS_SEARCHED";

export const SET_RENEWAL_APPROVED = "SET_RENEWAL_APPROVED";
export const SET_RENEWAL_APPROVED_SEARCHED = "SET_RENEWAL_APPROVED_SEARCHED";
export const SET_RENEWAL_ALL_APPROVED = "SET_RENEWAL_ALL_APPROVED";
export const SET_RENEWAL_ALL_APPROVED_SEARCHED =
  "SET_RENEWAL_ALL_APPROVED_SEARCHED";

export const SET_RENEWAL_DECLINED = "SET_RENEWAL_DECLINED";
export const SET_RENEWAL_DECLINED_SEARCHED = "SET_RENEWAL_DECLINED_SEARCHED";
export const SET_RENEWAL_ALL_DECLINED = "SET_RENEWAL_ALL_DECLINED";
export const SET_RENEWAL_ALL_DECLINED_SEARCHED =
  "SET_RENEWAL_ALL_DECLINED_SEARCHED";

export const SET_RENEWAL_UNDER_SUPERVISION =
  "SET_RENEWAL_UNDER_SUPERVISION";
export const SET_RENEWAL_UNDER_SUPERVISION_SEARCHED =
  "SET_RENEWAL_UNDER_SUPERVISION_SEARCHED";
export const SET_RENEWAL_OTHERS_UNDER_SUPERVISION =
  "SET_RENEWAL_OTHERS_UNDER_SUPERVISION";
export const SET_RENEWAL_OTHERS_UNDER_SUPERVISION_SEARCHED =
  "SET_RENEWAL_OTHERS_UNDER_SUPERVISION_SEARCHED";

export const SET_RENEWAL_APPROVED_PAYMENT =
  "SET_RENEWAL_APPROVED_PAYMENT";
export const SET_RENEWAL_APPROVED_PAYMENT_SEARCHED =
  "SET_RENEWAL_APPROVED_PAYMENT_SEARCHED";
export const SET_RENEWAL_OTHERS_APPROVED_PAYMENT =
  "SET_RENEWAL_OTHERS_APPROVED_PAYMENT";
export const SET_RENEWAL_OTHERS_APPROVED_PAYMENT_SEARCHED =
  "SET_RENEWAL_OTHERS_APPROVED_PAYMENT_SEARCHED";

export const SET_RENEWAL_DECLINED_PAYMENT =
  "SET_RENEWAL_DECLINED_PAYMENT";
export const SET_RENEWAL_DECLINED_PAYMENT_SEARCHED =
  "SET_RENEWAL_DECLINED_PAYMENT_SEARCHED";
export const SET_RENEWAL_OTHERS_DECLINED_PAYMENT =
  "SET_RENEWAL_OTHERS_DECLINED_PAYMENT";
export const SET_RENEWAL_OTHERS_DECLINED_PAYMENT_SEARCHED =
  "SET_RENEWAL_OTHERS_DECLINED_PAYMENT_SEARCHED";

export const SET_RENEWAL_ON_REVIEW =
  "SET_RENEWAL_ON_REVIEW";
export const SET_RENEWAL_ON_REVIEW_SEARCHED =
  "SET_RENEWAL_ON_REVIEW_SEARCHED";
export const SET_RENEWAL_OTHERS_ON_REVIEW =
  "SET_RENEWAL_OTHERS_ON_REVIEW";
export const SET_RENEWAL_OTHERS_ON_REVIEW_SEARCHED =
  "SET_RENEWAL_OTHERS_ON_REVIEW_SEARCHED";

export const SET_RENEWAL_RE_EVALUATE =
  "SET_RENEWAL_RE_EVALUATE";
export const SET_RENEWAL_RE_EVALUATE_SEARCHED =
  "SET_RENEWAL_RE_EVALUATE_SEARCHED";
export const SET_RENEWAL_OTHERS_RE_EVALUATE =
  "SET_RENEWAL_OTHERS_RE_EVALUATE";
export const SET_RENEWAL_OTHERS_RE_EVALUATE_SEARCHED =
  "SET_RENEWAL_OTHERS_RE_EVALUATE_SEARCHED";

export const SET_RENEWAL_CONFIRMED =
  "SET_RENEWAL_CONFIRMED";
export const SET_RENEWAL_CONFIRMED_SEARCHED =
  "SET_RENEWAL_CONFIRMED_SEARCHED";
export const SET_RENEWAL_OTHERS_CONFIRMED =
  "SET_RENEWAL_OTHERS_CONFIRMED";
export const SET_RENEWAL_OTHERS_CONFIRMED_SEARCHED =
  "SET_RENEWAL_OTHERS_CONFIRMED_SEARCHED";

  export const SET_RENEWAL_RETURNED_TO_ME =
  "SET_RENEWAL_RETURNED_TO_ME";
export const SET_RENEWAL_RETURNED_TO_ME_SEARCHED =
  "SET_RENEWAL_RETURNED_TO_ME_SEARCHED";
export const SET_RENEWAL_RETURNED_TO_OTHERS =
  "SET_RENEWAL_RETURNED_TO_OTHERS";
export const SET_RENEWAL_RETURNED_TO_OTHERS_SEARCHED =
  "SET_RENEWAL_RETURNED_TO_OTHERS_SEARCHED";

export const SET_RENEWAL_PENDING_PAYMENT =
  "SET_RENEWAL_PENDING_PAYMENT";
export const SET_RENEWAL_PENDING_PAYMENT_SEARCHED = 
  "SET_RENEWAL_PENDING_PAYMENT_SEARCHED";
export const SET_RENEWAL_OTHERS_PENDING_PAYMENT = 
  "SET_RENEWAL_OTHERS_PENDING_PAYMENT";
export const SET_RENEWAL_OTHERS_PENDING_PAYMENT_SEARCHED =
  "SET_RENEWAL_OTHERS_PENDING_PAYMENT_SEARCHED";

export const SET_RENEWAL_LICENSED =
  "SET_RENEWAL_LICENSED";
export const SET_RENEWAL_LICENSED_SEARCHED = 
  "SET_RENEWAL_LICENSED_SEARCHED";
export const SET_RENEWAL_OTHERS_LICENSED = 
  "SET_RENEWAL_OTHERS_LICENSED";
export const SET_RENEWAL_OTHERS_LICENSED_SEARCHED =
  "SET_RENEWAL_OTHERS_LICENSED_SEARCHED";

export const SET_RENEWAL_ALL_LICENSED =
  "SET_RENEWAL_ALL_LICENSED";
export const SET_RENEWAL_ALL_LICENSED_SEARCHED = 
  "SET_RENEWAL_ALL_LICENSED_SEARCHED"; 

export const SET_RENEWAL_RE_APPLY =
  "SET_RENEWAL_RE_APPLY";
export const SET_RENEWAL_RE_APPLY_SEARCHED = 
  "SET_RENEWAL_RE_APPLY_SEARCHED";
export const SET_RENEWAL_OTHERS_RE_APPLY = 
  "SET_RENEWAL_OTHERS_RE_APPLY";
export const SET_RENEWAL_OTHERS_RE_APPLY_SEARCHED =
  "SET_RENEWAL_OTHERS_RE_APPLY_SEARCHED";

export const SET_RENEWAL_DECLINE_CONFIRMED =
  "SET_RENEWAL_DECLINE_CONFIRMED";
export const SET_RENEWAL_DECLINE_CONFIRMED_SEARCHED = 
  "SET_RENEWAL_DECLINE_CONFIRMED_SEARCHED";
export const SET_RENEWAL_OTHERS_DECLINE_CONFIRMED = 
  "SET_RENEWAL_OTHERS_DECLINE_CONFIRMED";
export const SET_RENEWAL_OTHERS_DECLINE_CONFIRMED_SEARCHED =
  "SET_RENEWAL_OTHERS_DECLINE_CONFIRMED_SEARCHED";

export const SET_RENEWAL_UNDER_SUPER_VISION_CONFIRMED =
  "SET_RENEWAL_UNDER_SUPER_VISION_CONFIRMED";
export const SET_RENEWAL_UNDER_SUPER_VISION_CONFIRMED_SEARCHED = 
  "SET_RENEWAL_UNDER_SUPER_VISION_CONFIRMED_SEARCHED";
export const SET_RENEWAL_OTHERS_UNDER_SUPER_VISION_CONFIRMED = 
  "SET_RENEWAL_OTHERS_UNDER_SUPER_VISION_CONFIRMED";
export const SET_RENEWAL_OTHERS_UNDER_SUPER_VISION_CONFIRMED_SEARCHED =
  "SET_RENEWAL_OTHERS_UNDER_SUPER_VISION_CONFIRMED_SEARCHED";

const baseUrl = "https://hrlicensurebe.dev.k8s.sandboxaddis.com/api";

export default {
  SET_RENEWAL_UNASSIGNED,
  SET_RENEWAL_UNASSIGNED_SEARCHED,

  SET_RENEWAL_UNFINISHED,
  SET_RENEWAL_UNFINISHED_SEARCHED,
  SET_RENEWAL_OTHERS_UNFINISHED,
  SET_RENEWAL_OTHERS_UNFINISHED_SEARCHED,

  SET_RENEWAL_ASSIGNED_TO_YOU,
  SET_RENEWAL_ASSIGNED_TO_YOU_SEARCHED,
  SET_RENEWAL_ASSIGNED_TO_OTHERS,
  SET_RENEWAL_ASSIGNED_TO_OTHERS_SEARCHED,

  SET_RENEWAL_APPROVED,
  SET_RENEWAL_APPROVED_SEARCHED,
  SET_RENEWAL_ALL_APPROVED,
  SET_RENEWAL_ALL_APPROVED_SEARCHED,

  SET_RENEWAL_DECLINED,
  SET_RENEWAL_DECLINED_SEARCHED,
  SET_RENEWAL_ALL_DECLINED,
  SET_RENEWAL_ALL_DECLINED_SEARCHED,

  SET_RENEWAL_UNDER_SUPERVISION,
  SET_RENEWAL_UNDER_SUPERVISION_SEARCHED,
  SET_RENEWAL_OTHERS_UNDER_SUPERVISION,
  SET_RENEWAL_OTHERS_UNDER_SUPERVISION_SEARCHED,

  SET_RENEWAL_APPROVED_PAYMENT,
  SET_RENEWAL_APPROVED_PAYMENT_SEARCHED,
  SET_RENEWAL_OTHERS_APPROVED_PAYMENT,
  SET_RENEWAL_OTHERS_APPROVED_PAYMENT_SEARCHED,

  SET_RENEWAL_DECLINED_PAYMENT,
  SET_RENEWAL_DECLINED_PAYMENT_SEARCHED,
  SET_RENEWAL_OTHERS_DECLINED_PAYMENT,
  SET_RENEWAL_OTHERS_DECLINED_PAYMENT_SEARCHED,

  SET_RENEWAL_ON_REVIEW,
  SET_RENEWAL_ON_REVIEW_SEARCHED,
  SET_RENEWAL_OTHERS_ON_REVIEW,
  SET_RENEWAL_OTHERS_ON_REVIEW_SEARCHED,

  SET_RENEWAL_RE_EVALUATE,
  SET_RENEWAL_RE_EVALUATE_SEARCHED,
  SET_RENEWAL_OTHERS_RE_EVALUATE,
  SET_RENEWAL_OTHERS_RE_EVALUATE_SEARCHED,

  SET_RENEWAL_CONFIRMED,
  SET_RENEWAL_CONFIRMED_SEARCHED,
  SET_RENEWAL_OTHERS_CONFIRMED,
  SET_RENEWAL_OTHERS_CONFIRMED_SEARCHED,

  SET_RENEWAL_RETURNED_TO_ME,
  SET_RENEWAL_RETURNED_TO_ME_SEARCHED,
  SET_RENEWAL_RETURNED_TO_OTHERS,
  SET_RENEWAL_RETURNED_TO_OTHERS_SEARCHED,

  SET_RENEWAL_PENDING_PAYMENT,
  SET_RENEWAL_PENDING_PAYMENT_SEARCHED,
  SET_RENEWAL_OTHERS_PENDING_PAYMENT,
  SET_RENEWAL_OTHERS_PENDING_PAYMENT_SEARCHED,

  SET_RENEWAL_LICENSED,
  SET_RENEWAL_LICENSED_SEARCHED,
  SET_RENEWAL_OTHERS_LICENSED,
  SET_RENEWAL_OTHERS_LICENSED_SEARCHED,

  SET_RENEWAL_ALL_LICENSED,
  SET_RENEWAL_ALL_LICENSED_SEARCHED,

  SET_RENEWAL_RE_APPLY,
  SET_RENEWAL_RE_APPLY_SEARCHED,
  SET_RENEWAL_OTHERS_RE_APPLY,
  SET_RENEWAL_OTHERS_RE_APPLY_SEARCHED,

  SET_RENEWAL_DECLINE_CONFIRMED,
  SET_RENEWAL_DECLINE_CONFIRMED_SEARCHED,
  SET_RENEWAL_OTHERS_DECLINE_CONFIRMED,
  SET_RENEWAL_OTHERS_DECLINE_CONFIRMED_SEARCHED,

  SET_RENEWAL_UNDER_SUPER_VISION_CONFIRMED,
  SET_RENEWAL_UNDER_SUPER_VISION_CONFIRMED_SEARCHED,
  SET_RENEWAL_OTHERS_UNDER_SUPER_VISION_CONFIRMED,
  SET_RENEWAL_OTHERS_UNDER_SUPER_VISION_CONFIRMED_SEARCHED,
};
