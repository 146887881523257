export default {
    state: {
        applicantId: "",
        applicantTypeId: "",
        education: {
            departmentId: "",
            institutionId: "",
        },
        residenceWoredaId: "",
        professionalTypeId: "",
        occupationTypeId: "",
        expertLevelId: "",
        educationalLevelId: "",
        otherEducationalInstitution: "",
        otherProfessionalType: "",
        paymentSlip: null,
        healthExamCert: "",
        workExperience: "",
        workExperience2: "",
        letterFromHiringInstitution: "",
        professionalDocuments: [],
        certificate: "",
        diploma: "",
        transcript: "",
        serviceFee: "",
        previosLicense: "",
        cpd: "",
        cpd2: "",
        cpd3: "",
        cpd4: "",
        cpd5: "",
        payroll: "",
        passport: "",
        englishLanguage: "",
        herqa: "",
        letterfromOrg: "",
        professionalLicense: "",
        renewedLicense: "",
        coc: "",
        degree: "",
        educationalDocuments: [],
        eduEighthGrade: "",
        eduTenthGrade: "",
        eduTwelvethGrade: "",
        eduTranscript1: "",
        eduTranscript2: "",
        supportLetter: "",
        proCertificate: "",
        proDiploma: "",
        proTranscript: "",
        masters: "",
        mastersTranscript: "",
        phd: "",
        phdTranscript: "",
        renewedLicenseOfHealthFacility: "",

        buttons: [],
        applicationId: 0,
        documentSpec: [],
        draft: "",
        declinedFields: [],
        acceptedFields: [],
        remark: "",
    },
};