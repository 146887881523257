<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 77.96 73.998">
    <defs>
      <linearGradient
        id="a"
        y1=".061"
        x2="1"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#3674b9" />
        <stop offset=".562" stop-color="#3674b9" />
        <stop offset="1" stop-color="#fde68a" />
      </linearGradient>
    </defs>
    <path
      data-name="Intersection 32"
      d="M-11197.414-2745.917l-11.861-5.3-11.856 5.3h-.238l-6.535-11.351-12.9-2.732 1.382-13.117-8.825-9.8 8.825-9.8-1.382-13.116 12.9-2.732 6.535-11.351h.238l11.856 5.3 11.861-5.3h.238l6.532 11.351 12.9 2.732-1.381 13.116 8.827 9.8-8.827 9.8 1.381 13.117-12.9 2.732-6.532 11.351zm-1.715-5.776l5.541-9.627 10.87-2.3-1.164-11.045 7.432-8.251-7.432-8.251 1.164-11.045-10.87-2.3-5.541-9.627-10.146 4.532-10.145-4.532-5.541 9.627-10.87 2.3 1.164 11.045-7.432 8.251 7.432 8.251-1.164 11.045 10.87 2.3 5.541 9.627 10.145-4.535zm-26.968-31.435l3.235-3.232 10.175 10.172 16.341-18.307 3.413 3.043-19.567 21.921z"
      transform="translate(11248.253 2819.915)"
      fill="url(#a)"
    />
  </svg>
</template>
