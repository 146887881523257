export default {
    getLicense: (state) => state.license,
    getRenewalHealthExamCert: (state) => state.healthExamCert,
    getRenewalServiceFee: (state) => state.serviceFee,
    getRenewalCpd: (state) => state.cpd,
    getRenewalCpd2: (state) => state.cpd2,
    getRenewalCpd3: (state) => state.cpd3,
    getRenewalCpd4: (state) => state.cpd4,
    getRenewalCpd5: (state) => state.cpd5,
    getRenewalWorkExperience: (state) => state.workExperience,
    getRenewalWorkExperience2: (state) => state.workExperience2,
    getRenewalLicense: (state) => state.letterFromHiringInstitution,
    getProfessionalDocuments: (state) => state.professionalDocuments,
    getCertificate: (state) => state.certificate,
    getDiploma: (state) => state.diploma,
    getTranscript: (state) => state.transcript,
    getPreviousLicense: (state) => state.previosLicense,
    getPayroll: (state) => state.payroll,
    getButtons: (state) => state.buttons,
    getApplicationId: (state) => state.applicationId,
    getDocumentSpec: (state) => state.documentSpec,
    getDraft: (state) => state.draft,
    getRemark: (state) => state.remark,
    getDeclinedFields: (state) => state.declinedFields,
    getAcceptedFields: (state) => state.acceptedFields,
    getPassport: (state) => state.passport,
    getEnglishLanguage: (state) => state.englishLanguage,
    getHerqa: (state) => state.herqa,
    getLetterfromOrg: (state) => state.letterfromOrg,
    getProfessionalLicense: (state) => state.professionalLicense,
    getRenewedLicense: (state) => state.renewedLicense,
    getCoc: (state) => state.coc,
    getDegree: (state) => state.degree,
    getEducationalDocuments: (state) => state.educationalDocuments,
    getEduEighth: (state) => state.eduEighthGrade,
    getEduTenth: (state) => state.eduTenthGrade,
    getEduTwelveth: (state) => state.eduTwelvethGrade,
    getEduTranscript1: (state) => state.eduTranscript1,
    getEduTranscript2: (state) => state.eduTranscript2,
    getSupportLetter: (state) => state.supportLetter,
    getProCertificate: (state) => state.proCertificate,
    getProTranscript: (state) => state.proTranscript,
    getProDiploma: (state) => state.proDiploma,
    getMasters: (state) => state.masters,
    getMastersTranscript: (state) => state.mastersTranscript,
    getPhd: (state) => state.phd,
    getPhdTranscript: (state) => state.phdTranscript,
    getRenewedLicenseOfHealthFacility: (state) =>
        state.renewedLicenseOfHealthFacility,
};