export default {
  state: {
    applicantId: "",
    residenceWoredaId: "",
    expertLevelId: "",
    applicantTitle: "",
    whomGoodStandingFor: "",
    licenseIssuedDate: "",
    whoIssued: "",
    licenseRegistrationNumber: "",
    applicantPositionId: "",
    professionalTypeId: "",
    licenseCopy: "",
    serviceFee: "",
    otherProfessionalType: "",
    goodStandingLetter: "",
    buttons: [],
    applicationId: 0,
    documentSpec: [],
    draft: "",
    declinedFields: [],
    acceptedFields: [],
    remark: "",
  },
};
