<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70.108 67">
    <defs>
      <linearGradient
        id="a"
        x2="1.119"
        y2="1.084"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#3674b9" />
        <stop offset=".492" stop-color="#3674b9" />
        <stop offset="1" stop-color="#fde68a" />
      </linearGradient>
    </defs>
    <path
      data-name="Intersection 85"
      d="M-9778.937-3628.821c-6.322 0-15.5-3.34-27.808-15.74-15.477-15.612-16.723-26.249-15.04-32.421a6.825 6.825 0 013.086-4.043l4.608-2.792a3.444 3.444 0 014.674 1.061l2.24 3.389v-13.626a3.012 3.012 0 011.966-2.827h51a3.007 3.007 0 011.965 2.827v36.469a2.99 2.99 0 01-2.979 2.992h-45.68c.464.493.96 1.009 1.508 1.562 5.057 5.1 7.558 6.478 9.57 7.583l.609.34a1.12 1.12 0 001.434-.306l3.212-4.166a3.462 3.462 0 014.659-.762l11.031 7.417a3.514 3.514 0 011.054 4.712l-2.768 4.652a6.771 6.771 0 01-4.006 3.111 16.219 16.219 0 01-4.256.57zm-33.945-52.973l-4.618 2.794a4.47 4.47 0 00-2.033 2.642c-1.517 5.57-.236 15.321 14.44 30.126s24.345 16.1 29.87 14.569a4.45 4.45 0 002.617-2.052l2.768-4.652a1.145 1.145 0 00-.343-1.532l-11.031-7.42a1.131 1.131 0 00-1.52.249l-3.212 4.166a3.425 3.425 0 01-4.413.918l-.594-.328c-2.142-1.179-4.809-2.647-10.1-7.987s-6.749-8.032-7.918-10.2l-.326-.6a3.494 3.494 0 01.908-4.45l4.132-3.241a1.151 1.151 0 00.247-1.532l-7.356-11.13a1.139 1.139 0 00-.942-.506 1.116 1.116 0 00-.577.166zm8.229-11.66a.656.656 0 00-.187.461v17.164l2.777 4.2a3.528 3.528 0 01-.755 4.7l-4.132 3.241a1.137 1.137 0 00-.3 1.441l.338.619a29.758 29.758 0 003.9 5.735h47.788a.643.643 0 00.644-.636v-36.469a.656.656 0 00-.187-.461.636.636 0 00-.456-.187h-48.977a.64.64 0 00-.453.191zm45.255 34.377l-11.969-12.933a1.185 1.185 0 01.057-1.667 1.16 1.16 0 011.65.059l11.971 12.933a1.184 1.184 0 01-.059 1.665 1.157 1.157 0 01-.8.318 1.16 1.16 0 01-.852-.376zm-42.2.057a1.185 1.185 0 01-.06-1.665l11.974-12.933a1.161 1.161 0 011.606-.015 1.186 1.186 0 01.1 1.616l-11.974 12.941a1.155 1.155 0 01-.854.375 1.157 1.157 0 01-.794-.32zm19.552-14.7l-19.529-14.944a1.186 1.186 0 01-.225-1.653 1.164 1.164 0 011.638-.227l19.527 14.943a1.568 1.568 0 001.854 0l19.532-14.943a1.161 1.161 0 011.635.227 1.175 1.175 0 01.229.873 1.192 1.192 0 01-.451.78l-19.537 14.944a3.917 3.917 0 01-2.338.774 3.92 3.92 0 01-2.337-.772z"
      transform="translate(9822.354 3695.821)"
      fill="url(#a)"
    />
  </svg>
</template>
