<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 85.571 74.001"
    id="illustration-style"
  >
    <defs>
      <linearGradient
        id="a"
        y1=".061"
        x2="1.317"
        y2="1.183"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#3674b9" />
        <stop offset=".562" stop-color="#3674b9" />
        <stop offset="1" stop-color="#fde68a" />
      </linearGradient>
    </defs>
    <path
      data-name="Intersection 86"
      d="M-11198.206-2746a4.133 4.133 0 01-1.92-.5l-9.274-4.881a1.949 1.949 0 00-1.231 0l-9.272 4.881a3.594 3.594 0 01-3.842-.107 3.6 3.6 0 01-1.319-3.61l1.3-7.916h-42.982a5.933 5.933 0 01-5.929-5.926v-50.012a5.935 5.935 0 015.929-5.928h73.715a5.935 5.935 0 015.928 5.928v50.01a5.935 5.935 0 01-5.928 5.928h-3.228l1.3 7.916a3.6 3.6 0 01-1.316 3.61 3.26 3.26 0 01-1.931.607zm-8.828-9.878l6.558 3.451-1.269-7.732v-.025l-.812-4.957a15.334 15.334 0 01-7.454 1.928 15.326 15.326 0 01-7.455-1.928l-2.086 12.715 6.557-3.451a6.494 6.494 0 012.981-.682 6.479 6.479 0 012.98.682zm-60.566-58.194v50.012a.861.861 0 00.85.847h43.817l.929-5.662c0-.023.009-.045.014-.068a15.33 15.33 0 01-3.437-9.683 15.427 15.427 0 0115.413-15.41 15.426 15.426 0 0115.409 15.41 15.328 15.328 0 01-3.434 9.683c0 .023.011.045.014.068l.929 5.659h4.061a.858.858 0 00.846-.847v-50.012a.858.858 0 00-.846-.847h-73.715a.861.861 0 00-.85.85zm47.255 35.449a10.342 10.342 0 0010.328 10.331 10.345 10.345 0 0010.333-10.331 10.345 10.345 0 00-10.333-10.331 10.342 10.342 0 00-10.329 10.331zm-39.254-7.9a2.54 2.54 0 01-2.54-2.54 2.54 2.54 0 012.54-2.54h24.388a2.539 2.539 0 012.539 2.54 2.539 2.539 0 01-2.539 2.54zm0-13.773a2.54 2.54 0 01-2.54-2.54 2.54 2.54 0 012.54-2.54h57.58a2.537 2.537 0 012.539 2.54 2.537 2.537 0 01-2.539 2.54z"
      transform="translate(11272.68 2820)"
      fill="url(#a)"
    />
  </svg>
</template>
