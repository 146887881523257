<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 74.168 74.001">
    <defs>
      <linearGradient
        id="a"
        y1=".061"
        x2="1.179"
        y2="1.182"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#3674b9" />
        <stop offset=".562" stop-color="#3674b9" />
        <stop offset="1" stop-color="#fde68a" />
      </linearGradient>
    </defs>
    <path
      data-name="Intersection 87"
      d="M-11227.187-2746a2.32 2.32 0 01-1.452-2.152 2.315 2.315 0 012.316-2.316 32.485 32.485 0 0032.447-32.448 32.214 32.214 0 00-9.521-22.927 32.226 32.226 0 00-22.927-9.524 32.486 32.486 0 00-32.448 32.451 32.353 32.353 0 009.272 22.709v-4.167a2.317 2.317 0 012.315-2.319 2.32 2.32 0 012.319 2.319v9.269a2.318 2.318 0 01-2.319 2.319h-9.271a2.316 2.316 0 01-2.316-2.319 2.315 2.315 0 012.316-2.316h3.2a36.962 36.962 0 01-10.155-25.495 36.84 36.84 0 0110.86-26.222 36.843 36.843 0 0126.223-10.863 36.829 36.829 0 0126.205 10.88 36.829 36.829 0 0110.88 26.205 36.843 36.843 0 01-10.863 26.222 36.8 36.8 0 01-22.668 10.693zm14.063-20.735l-4.113-2.163a.853.853 0 00-.544 0l-4.113 2.163a1.841 1.841 0 01-.85.224 1.457 1.457 0 01-.854-.269 1.6 1.6 0 01-.584-1.6l.575-3.508h-19.057a2.631 2.631 0 01-2.627-2.627v-22.174a2.629 2.629 0 012.627-2.627h32.683a2.631 2.631 0 012.628 2.627v22.174a2.631 2.631 0 01-2.628 2.627h-1.433l.575 3.508a1.6 1.6 0 01-.583 1.6 1.451 1.451 0 01-.853.269 1.841 1.841 0 01-.849-.224zm-3.062-4.156l2.907 1.529-.563-3.426a.024.024 0 000-.014l-.359-2.194a6.82 6.82 0 01-3.3.852 6.823 6.823 0 01-3.3-.852l-.926 5.634 2.908-1.529a2.883 2.883 0 011.322-.3 2.9 2.9 0 011.31.3zm-26.852-25.8v22.174a.381.381 0 00.374.374h19.428l.41-2.511c0-.011.006-.02.009-.028a6.79 6.79 0 01-1.523-4.295 6.838 6.838 0 016.832-6.832 6.838 6.838 0 016.831 6.832 6.816 6.816 0 01-1.521 4.295.125.125 0 00.006.028l.411 2.508h1.8a.382.382 0 00.377-.374v-22.174a.382.382 0 00-.377-.376h-32.683a.381.381 0 00-.374.379zm20.948 15.716a4.587 4.587 0 004.581 4.581 4.586 4.586 0 004.58-4.581 4.586 4.586 0 00-4.58-4.581 4.587 4.587 0 00-4.581 4.58zm-17.4-3.505a1.125 1.125 0 01-1.124-1.124 1.126 1.126 0 011.124-1.127h10.813a1.126 1.126 0 011.127 1.127 1.125 1.125 0 01-1.127 1.124zm0-6.1a1.126 1.126 0 01-1.124-1.127 1.125 1.125 0 011.124-1.127h25.528a1.126 1.126 0 011.127 1.127 1.126 1.126 0 01-1.127 1.127z"
      transform="translate(11263.405 2820)"
      fill="url(#a)"
    />
  </svg>
</template>
