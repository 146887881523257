<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.946 67.651">
    <defs>
      <linearGradient
        id="a"
        x2="1.163"
        y2="1.254"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#3674b9" />
        <stop offset=".492" stop-color="#3674b9" />
        <stop offset="1" stop-color="#fde68a" />
      </linearGradient>
    </defs>
    <path
      data-name="Intersection 81"
      d="M-6945.518-1840.349a12.175 12.175 0 01-8.673-3.136 11.181 11.181 0 01-3.367-8.5c0-1.359.047-2.7.137-4a39.834 39.834 0 01.553-4.276 33.653 33.653 0 011.06-4.3 21.071 21.071 0 011.783-4.008 15.1 15.1 0 012.687-3.474 11.88 11.88 0 013.861-2.4 13.447 13.447 0 014.928-.885 5.005 5.005 0 012.671 1.123c.813.524 1.752 1.123 2.787 1.778a15.886 15.886 0 003.6 1.573 14.443 14.443 0 004.406.706 14.424 14.424 0 004.4-.706 15.92 15.92 0 003.6-1.573c1.046-.662 1.983-1.26 2.784-1.778a5.019 5.019 0 012.673-1.123 13.446 13.446 0 014.928.885 11.87 11.87 0 013.861 2.4 15.112 15.112 0 012.689 3.474 20.914 20.914 0 011.782 4.006 33.112 33.112 0 011.062 4.3 40.1 40.1 0 01.554 4.274c.091 1.289.136 2.633.138 4a11.179 11.179 0 01-3.367 8.5 12.182 12.182 0 01-8.673 3.136zm-.485-30.4a7.86 7.86 0 00-2.57 1.583 11.163 11.163 0 00-1.975 2.565 17.175 17.175 0 00-1.45 3.26 29.544 29.544 0 00-.934 3.789 35.886 35.886 0 00-.5 3.85 53.536 53.536 0 00-.128 3.721 7.3 7.3 0 002.125 5.627 8.206 8.206 0 005.916 2.043h32.865a8.213 8.213 0 005.917-2.043 7.312 7.312 0 002.125-5.627c0-1.272-.043-2.523-.129-3.721a36.2 36.2 0 00-.5-3.852 29.486 29.486 0 00-.934-3.787 17.153 17.153 0 00-1.452-3.26 11.165 11.165 0 00-1.974-2.565 7.855 7.855 0 00-2.568-1.583 9.261 9.261 0 00-3.151-.6c-.14.074-.389.215-.794.476a418.98 418.98 0 01-2.815 1.8 19.9 19.9 0 01-4.523 2 18.5 18.5 0 01-5.635.9 18.452 18.452 0 01-5.635-.9 19.879 19.879 0 01-4.52-2c-1.048-.664-2-1.27-2.819-1.8-.4-.261-.653-.4-.793-.476a9.259 9.259 0 00-3.146.598zm4.864-9.435a15.7 15.7 0 01-4.817-11.521 15.7 15.7 0 014.817-11.52 15.977 15.977 0 0111.623-4.774 15.975 15.975 0 0111.623 4.774 15.694 15.694 0 014.817 11.52 15.7 15.7 0 01-4.815 11.521 15.977 15.977 0 01-11.625 4.773 15.983 15.983 0 01-11.622-4.775zm2.828-20.238a11.727 11.727 0 00-3.644 8.717 11.728 11.728 0 003.644 8.718 11.945 11.945 0 008.8 3.612 11.94 11.94 0 008.8-3.612 11.728 11.728 0 003.644-8.718 11.727 11.727 0 00-3.644-8.717 11.94 11.94 0 00-8.8-3.612 11.942 11.942 0 00-8.8 3.61z"
      transform="translate(6957.557 1908)"
      fill="url(#a)"
    />
  </svg>
</template>
