<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.169 67">
    <defs>
      <linearGradient
        id="a"
        x2="1.143"
        y2="1.196"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#3674b9" />
        <stop offset=".492" stop-color="#3674b9" />
        <stop offset="1" stop-color="#fde68a" stop-opacity=".608" />
      </linearGradient>
    </defs>
    <path
      data-name="Intersection 20"
      d="M-6800.787-5598.846v-1.967h21.777v-21.642h-17.711v19.675h-1.969v-19.675h-2.1v-5.9h3.935v-7.181l11.28-16.982h15.159l2.228-3.381v-9.943h9.839v5.749h-7.872v4.195l2.227 3.381h15.159l11.28 16.981v7.183h3.935v5.9h-2.1v19.675h-1.967v-19.675h-17.71v21.642h21.777v1.967zm37.521-17.707v15.74h5.9v-33.447h-8.856v-1.968h10.824v-1.966h-23.615v1.966h10.824v1.968h-8.856v33.447h5.9v-15.74a3.94 3.94 0 013.935-3.935 3.941 3.941 0 013.944 3.935zm-5.9 0v15.74h3.937v-15.74a1.971 1.971 0 00-1.969-1.967 1.971 1.971 0 00-1.972 1.967zm13.776-7.87h19.808v-1.968h-19.808zm-43.424 0h19.81v-1.968h-19.81zm43.424-3.935h15.873v-5.9h-15.873zm-39.49 0h15.875v-5.9h-15.875zm39.035-11.8h2.422v3.935h13.056l-9.518-14.326h-12.815zm-38.183 3.935h13.056v-3.935h2.42l6.848-10.391h-12.808zm17.833-3.935h17.994l-9-13.656zm9.981-21.9h5.9v-1.967h-5.9zm20.663 59.284v-14.755a2.955 2.955 0 012.952-2.952 2.955 2.955 0 012.95 2.952v14.755zm1.967-14.755v12.788h1.968v-12.788a.984.984 0 00-.983-.984.985.985 0 00-.989.977zm-9.839 14.755v-14.755a2.955 2.955 0 012.952-2.952 2.954 2.954 0 012.95 2.952v14.755zm1.967-14.755v12.788h1.968v-12.788a.984.984 0 00-.983-.984.985.985 0 00-.989.977zm-35.421 14.755v-14.755a2.956 2.956 0 012.952-2.952 2.956 2.956 0 012.952 2.952v14.755zm1.967-14.755v12.788h1.968v-12.788a.985.985 0 00-.983-.984.985.985 0 00-.989.977zm-9.839 14.755v-14.755a2.956 2.956 0 012.952-2.952 2.956 2.956 0 012.952 2.952v14.755zm1.969-14.755v12.788h1.966v-12.788a.985.985 0 00-.983-.984.983.983 0 00-.986.977zm21.646-9.837a3.939 3.939 0 013.935-3.935 3.94 3.94 0 013.937 3.935 3.941 3.941 0 01-3.937 3.935 3.94 3.94 0 01-3.939-3.943zm1.967 0a1.97 1.97 0 001.967 1.968 1.971 1.971 0 001.969-1.968 1.97 1.97 0 00-1.969-1.967 1.97 1.97 0 00-1.97 1.959zm7.872-2.952v-1.967h1.967v1.967zm-4.919-11.8v-1.968h1.967v1.968zm-3.935 0v-1.968h1.968v1.968zm18.825-2.523v-1.968h-1.967v-1.967h1.967v1.967h1.967v1.968z"
      transform="translate(6800.787 5665.846)"
      fill="url(#a)"
    />
  </svg>
</template>
