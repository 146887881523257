export const SET_PROFILE = "SET_PROFILE";
export const SET_PERSONAL_INFO = "SET_PERSONAL_INFO";
export const SET_ADDRESS = "SET_ADDRESS";
export const SET_PHOTO = "SET_PHOTO";
export const SET_NATIONALITY = "SET_NATIONALITY";
export const SET_MARITAL_STATUS = "SET_MARITAL_STATUS";

export default {
  SET_PROFILE,
  SET_PERSONAL_INFO,
  SET_ADDRESS,
  SET_PHOTO,
  SET_NATIONALITY,
  SET_MARITAL_STATUS,
};
