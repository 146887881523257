<template>
  <h2 class="text-primary-200 blue-gradient-dark p-small text-sm sm:text-lg">
    HRIS License © Ministry of Health - Ethiopia 2021
  </h2>
</template>
<style lang="postcss" scoped>
h2 {
  background-image: linear-gradient(to right, #285180, #162b43);
}
</style>
