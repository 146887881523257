<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80.085 67.001">
    <defs>
      <linearGradient
        id="a"
        x2="1.125"
        y2="2.659"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#4185d0" />
        <stop offset="1" stop-color="#fde68a" />
      </linearGradient>
    </defs>
    <path
      data-name="Intersection 9"
      d="M-6815.824-2758a9.206 9.206 0 01-9.176-9.22v-41.909a9.2 9.2 0 019.176-9.219h17.519v-.838a5.869 5.869 0 015.051-5.815h16.594a5.869 5.869 0 015.051 5.815v.838h17.519a9.2 9.2 0 019.176 9.219v41.909a9.206 9.206 0 01-9.176 9.22zm-4.169-51.129v41.909a4.186 4.186 0 004.169 4.19h61.733a4.185 4.185 0 004.169-4.19v-41.909a4.186 4.186 0 00-4.169-4.19h-17.519v.838a5.86 5.86 0 01-5.839 5.867h-15.017a5.86 5.86 0 01-5.839-5.867v-.838h-17.519a4.187 4.187 0 00-4.17 4.19zm26.695-10.057v6.7a.836.836 0 00.833.838h15.017a.837.837 0 00.835-.838v-6.7a.837.837 0 00-.835-.838h-15.017a.836.836 0 00-.835.838zm17.517 51.128a2.51 2.51 0 01-2.5-2.514 2.511 2.511 0 012.5-2.516h18.354a2.51 2.51 0 012.5 2.516 2.509 2.509 0 01-2.5 2.514zm-14.182-2.514v-1.676a4.187 4.187 0 00-4.169-4.192h-11.678a4.188 4.188 0 00-4.172 4.192v1.676a2.51 2.51 0 01-2.5 2.514 2.509 2.509 0 01-2.5-2.514v-1.676a9.206 9.206 0 019.178-9.22h11.678a9.205 9.205 0 019.176 9.22v1.676a2.509 2.509 0 01-2.5 2.514 2.51 2.51 0 01-2.514-2.514zm14.182-10.9a2.511 2.511 0 01-2.5-2.514 2.51 2.51 0 012.5-2.514h18.354a2.509 2.509 0 012.5 2.514 2.51 2.51 0 01-2.5 2.514zm-32.532-11.735a8.373 8.373 0 018.34-8.383 8.374 8.374 0 018.343 8.383 8.374 8.374 0 01-8.343 8.382 8.373 8.373 0 01-8.341-8.375zm5 0a3.351 3.351 0 003.335 3.354 3.351 3.351 0 003.337-3.354 3.35 3.35 0 00-3.337-3.352 3.349 3.349 0 00-3.332 3.359zm27.528-1.676a2.51 2.51 0 01-2.5-2.514 2.51 2.51 0 012.5-2.514h18.354a2.509 2.509 0 012.5 2.514 2.509 2.509 0 01-2.5 2.514z"
      transform="translate(6825 2825)"
      fill="url(#a)"
    />
  </svg>
</template>
