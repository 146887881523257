export const SET_LICENSE = "SET_LICENSE";
export const SET_ACTIVE_STATE = "SET_ACTIVE_STATE";
export const SET_PASSPORT = "SET_PASSPORT";
export const SET_HEALTH_EXAM_CERT = "SET_HEALTH_EXAM_CERT";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_PROFESSIONAL_DOC_CERTIFICATE =
  "SET_PROFESSIONAL_DOC_CERTIFICATE";
export const SET_PROFESSIONAL_DOC_CERTIFICATE2 =
  "SET_PROFESSIONAL_DOC_CERTIFICATE2";
export const SET_PROFESSIONAL_DOC_CERTIFICATE3 =
  "SET_PROFESSIONAL_DOC_CERTIFICATE3";
export const SET_PROFESSIONAL_DOC_CERTIFICATE4 =
  "SET_PROFESSIONAL_DOC_CERTIFICATE4";
export const SET_PROFESSIONAL_DOC_CERTIFICATE5 =
  "SET_PROFESSIONAL_DOC_CERTIFICATE5";
export const SET_PROFESSIONAL_DOC_DIPLOMA = "SET_PROFESSIONAL_DOC_DIPLOMA";
export const SET_PROFESSIONAL_DOC_DIPLOMA2 = "SET_PROFESSIONAL_DOC_DIPLOMA2";
export const SET_PROFESSIONAL_DOC_DIPLOMA3 = "SET_PROFESSIONAL_DOC_DIPLOMA3";
export const SET_PROFESSIONAL_DOC_DIPLOMA4 = "SET_PROFESSIONAL_DOC_DIPLOMA4";
export const SET_PROFESSIONAL_DOC_DIPLOMA5 = "SET_PROFESSIONAL_DOC_DIPLOMA5";
export const SET_PROFESSIONAL_DOC_TRANSCRIPT =
  "SET_PROFESSIONAL_DOC_TRANSCRIPT";
export const SET_PROFESSIONAL_DOC_TRANSCRIPT2 =
  "SET_PROFESSIONAL_DOC_TRANSCRIPT2";
export const SET_PROFESSIONAL_DOC_TRANSCRIPT3 =
  "SET_PROFESSIONAL_DOC_TRANSCRIPT3";
export const SET_PROFESSIONAL_DOC_TRANSCRIPT4 =
  "SET_PROFESSIONAL_DOC_TRANSCRIPT4";
export const SET_PROFESSIONAL_DOC_TRANSCRIPT5 =
  "SET_PROFESSIONAL_DOC_TRANSCRIPT5";
export const SET_HERQA = "SET_HERQA";
export const SET_SUPPORT_LETTER = "SET_SUPPORT_LETTER";
export const SET_COC = "SET_COC";
export const SET_COC2 = "SET_COC2";
export const SET_COC3 = "SET_COC3";
export const SET_EDUCATIONAL_DOCUMENT = "SET_EDUCATIONAL_DOCUMENT";
export const SET_WORK_EXPERIENCE = "SET_WORK_EXPERIENCE";
export const SET_WORK_EXPERIENCE2 = "SET_WORK_EXPERIENCE2";
export const SET_SERVICE_FEE = "SET_SERVICE_FEE";
export const SET_RENEWED_LICENSE = "SET_RENEWED_LICENSE";
export const SET_PROFESSIONAL_LICENSE = "SET_PROFESSIONAL_LICENSE";
export const SET_PROFESSIONAL_LICENSE2 = "SET_PROFESSIONAL_LICENSE2";
export const SET_PAYROLL = "SET_PAYROLL";
export const SET_BUTTONS = "SET_BUTTONS";
export const SET_APPLICATION_ID = "SET_APPLICATION_ID";
export const SET_DOCUMENT_SPEC = "SET_DOCUMENT_SPEC";
export const SET_DRAFT = "SET_DRAFT";
export const SET_DECLINED_FIELDS = "SET_DECLINED_FIELDS";
export const SET_ACCEPTED_FIELDS = "SET_ACCEPTED_FIELDS";
export const SET_REMARK = "SET_REMARK";
export const SET_DEGREE = "SET_DEGREE";
export const SET_TRANSCRIPT = "SET_TRANSCRIPT";
export const SET_TRANSCRIPT2 = "SET_TRANSCRIPT2";
export const SET_DIPLOMA = "SET_DIPLOMA";
export const SET_PRO_CERTIFICATE = "SET_PRO_CERTIFICATE";
export const SET_PRO_DIPLOMA = "SET_PRO_DIPLOMA";
export const SET_PRO_TRANSCRIPT = "SET_PRO_TRANSCRIPT";
export const SET_EDU_EIGHTH = "SET_EDU_EIGHTH";
export const SET_EDU_TENTH = "SET_EDU_TENTH";
export const SET_EDU_TWELVETH = "SET_EDU_TWELVETH";
export const SET_EDU_TRANSCRIPT1 = "SET_EDU_TRANSCRIPT1";
export const SET_EDU_TRANSCRIPT2 = "SET_EDU_TRANSCRIPT2";
export const SET_MASTERS = "SET_MASTERS";
export const SET_MASTERS_TRANSCRIPT = "SET_MASTERS_TRANSCRIPT";
export const SET_MASTERS_TRANSCRIPT2 = "SET_MASTERS_TRANSCRIPT2";
export const SET_PHD = "SET_PHD";
export const SET_PHD_TRANSCRIPT = "SET_PHD_TRANSCRIPT";
export const SET_PHD_TRANSCRIPT2 = "SET_PHD_TRANSCRIPT2";
export const SET_RENEWED_LICENSE_OF_HEALTH_FACILITY =
  "SET_RENEWED_LICENSE_OF_HEALTH_FACILITY";
export const SET_REQUEST_LETTER_FROM_HIRING_HEALTH_FACILITY =
  "SET_REQUEST_LETTER_FROM_HIRING_HEALTH_FACILITY";

export default {
  SET_LICENSE,
  SET_ACTIVE_STATE,
  SET_PASSPORT,
  SET_HEALTH_EXAM_CERT,
  SET_LANGUAGE,
  SET_PROFESSIONAL_DOC_CERTIFICATE,
  SET_PROFESSIONAL_DOC_CERTIFICATE2,
  SET_PROFESSIONAL_DOC_CERTIFICATE3,
  SET_PROFESSIONAL_DOC_CERTIFICATE4,
  SET_PROFESSIONAL_DOC_CERTIFICATE5,
  SET_PROFESSIONAL_DOC_DIPLOMA,
  SET_PROFESSIONAL_DOC_DIPLOMA2,
  SET_PROFESSIONAL_DOC_DIPLOMA3,
  SET_PROFESSIONAL_DOC_DIPLOMA4,
  SET_PROFESSIONAL_DOC_DIPLOMA5,
  SET_PROFESSIONAL_DOC_TRANSCRIPT,
  SET_PROFESSIONAL_DOC_TRANSCRIPT2,
  SET_PROFESSIONAL_DOC_TRANSCRIPT3,
  SET_PROFESSIONAL_DOC_TRANSCRIPT4,
  SET_PROFESSIONAL_DOC_TRANSCRIPT5,
  SET_HERQA,
  SET_SUPPORT_LETTER,
  SET_COC,
  SET_COC2,
  SET_COC3,
  SET_EDUCATIONAL_DOCUMENT,
  SET_WORK_EXPERIENCE,
  SET_WORK_EXPERIENCE2,
  SET_PAYROLL,
  SET_SERVICE_FEE,
  SET_RENEWED_LICENSE,
  SET_PROFESSIONAL_LICENSE,
  SET_PROFESSIONAL_LICENSE2,
  SET_DEGREE,
  SET_PRO_CERTIFICATE,
  SET_PRO_DIPLOMA,
  SET_PRO_TRANSCRIPT,
  SET_TRANSCRIPT,
  SET_TRANSCRIPT2,
  SET_DIPLOMA,
  SET_EDU_EIGHTH,
  SET_EDU_TENTH,
  SET_EDU_TWELVETH,
  SET_EDU_TRANSCRIPT1,
  SET_EDU_TRANSCRIPT2,
  SET_MASTERS,
  SET_MASTERS_TRANSCRIPT,
  SET_MASTERS_TRANSCRIPT2,
  SET_PHD,
  SET_PHD_TRANSCRIPT,
  SET_PHD_TRANSCRIPT2,
  SET_RENEWED_LICENSE_OF_HEALTH_FACILITY,
  SET_REQUEST_LETTER_FROM_HIRING_HEALTH_FACILITY,
  SET_BUTTONS,
  SET_APPLICATION_ID,
  SET_DOCUMENT_SPEC,
  SET_DRAFT,
  SET_DECLINED_FIELDS,
  SET_ACCEPTED_FIELDS,
  SET_REMARK,
};
