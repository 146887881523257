export const SET_LICENSE = "SET_LICENSE";
export const SET_LICENSE_COPY = "SET_LICENSE_COPY";
export const SET_SERVICE_FEE = "SET_SERVICE_FEE";
export const SET_GOODSTANDING_LETTER = "SET_GOODSTANDING_LETTER";
export const SET_BUTTONS = "SET_BUTTONS";
export const SET_APPLICATION_ID = "SET_APPLICATION_ID";
export const SET_DOCUMENT_SPEC = "SET_DOCUMENT_SPEC";
export const SET_DRAFT = "SET_DRAFT";
export const SET_DECLINED_FIELDS = "SET_DECLINED_FIELDS";
export const SET_ACCEPTED_FIELDS = "SET_ACCEPTED_FIELDS";
export const SET_REMARK = "SET_REMARK";

export default {
  SET_LICENSE,
  SET_LICENSE_COPY,
  SET_SERVICE_FEE,
  SET_GOODSTANDING_LETTER,
  SET_BUTTONS,
  SET_APPLICATION_ID,
  SET_DOCUMENT_SPEC,
  SET_DRAFT,
  SET_DECLINED_FIELDS,
  SET_ACCEPTED_FIELDS,
  SET_REMARK,
};
