export default {
  getLicense: (state) => state.license,
  getPassport: (state) => state.passport,
  getHealthExamCert: (state) => state.healthExamCert,
  getEnglishLanguage: (state) => state.englishLanguage,
  getProfessionalDocCertificate: (state) => state.professionalDocCertificate,
  getProfessionalDocCertificate2: (state) => state.professionalDocCertificate2,
  getProfessionalDocCertificate3: (state) => state.professionalDocCertificate3,
  getProfessionalDocCertificate4: (state) => state.professionalDocCertificate4,
  getProfessionalDocCertificate5: (state) => state.professionalDocCertificate5,
  getProfessionalDocDiploma: (state) => state.professionalDocDiploma,
  getProfessionalDocDiploma2: (state) => state.professionalDocDiploma2,
  getProfessionalDocDiploma3: (state) => state.professionalDocDiploma3,
  getProfessionalDocDiploma4: (state) => state.professionalDocDiploma4,
  getProfessionalDocDiploma5: (state) => state.professionalDocDiploma5,
  getProfessionalDocTranscript: (state) => state.professionalDocTranscript,
  getProfessionalDocTranscript2: (state) => state.professionalDocTranscript2,
  getProfessionalDocTranscript3: (state) => state.professionalDocTranscript3,
  getProfessionalDocTranscript4: (state) => state.professionalDocTranscript4,
  getProfessionalDocTranscript5: (state) => state.professionalDocTranscript5,
  getHerqa: (state) => state.herqa,
  getSupportLetter: (state) => state.supportLetter,
  getCoc: (state) => state.coc,
  getCoc2: (state) => state.coc2,
  getCoc3: (state) => state.coc3,
  getEducationalDocuments: (state) => state.educationalDocuments,
  getWorkExperience: (state) => state.workExperience,
  getWorkExperience2: (state) => state.workExperience2,
  getServiceFee: (state) => state.serviceFee,
  getRenewedLicense: (state) => state.renewedLicense,
  getPayroll: (state) => state.payroll,
  getDegree: (state) => state.degree,
  getTranscript: (state) => state.transcript,
  getTranscript2: (state) => state.transcript2,
  getDiploma: (state) => state.diploma,
  getProfessionalLicense: (state) => state.professionalLicense,
  getProfessionalLicense2: (state) => state.professionalLicense2,
  getProCertificate: (state) => state.proCertificate,
  getProTranscript: (state) => state.proTranscript,
  getProDiploma: (state) => state.proDiploma,
  getEduEighth: (state) => state.eduEighthGrade,
  getEduTenth: (state) => state.eduTenthGrade,
  getEduTwelveth: (state) => state.eduTwelvethGrade,
  getEduTranscript1: (state) => state.eduTranscript1,
  getEduTranscript2: (state) => state.eduTranscript2,
  getMasters: (state) => state.masters,
  getMastersTranscript: (state) => state.mastersTranscript,
  getMastersTranscript2: (state) => state.mastersTranscript2,
  getPhd: (state) => state.phd,
  getPhdTranscript: (state) => state.phdTranscript,
  getPhdTranscript2: (state) => state.phdTranscript2,
  getRenewedLicenseOfHealthFacility: (state) =>
    state.renewedLicenseOfHealthFacility,
  getRequestLetterFromHiringHealthFacility: (state) =>
    state.requestLetterFromHiringHealthFacility,
  getButtons: (state) => state.buttons,
  getApplicationId: (state) => state.applicationId,
  getDocumentSpec: (state) => state.documentSpec,
  getDraft: (state) => state.draft,
  getRemark: (state) => state.remark,
  getDeclinedFields: (state) => state.declinedFields,
  getAcceptedFields: (state) => state.acceptedFields,
};
