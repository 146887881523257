export const SET_NEW_LICENSE_UNASSIGNED = "SET_NEW_LICENSE_UNASSIGNED";
export const SET_NEW_LICENSE_UNASSIGNED_SEARCHED =
  "SET_NEW_LICENSE_UNASSIGNED_SEARCHED";

export const SET_NEW_LICENSE_UNFINISHED = "SET_NEW_LICENSE_UNFINISHED";
export const SET_NEW_LICENSE_UNFINISHED_SEARCHED =
  "SET_NEW_LICENSE_UNFINISHED_SEARCHED";
export const SET_NEW_LICENSE_OTHERS_UNFINISHED =
  "SET_NEW_LICENSE_OTHERS_UNFINISHED";
export const SET_NEW_LICENSE_OTHERS_UNFINISHED_SEARCHED =
  "SET_NEW_LICENSE_OTHERS_UNFINISHED_SEARCHED";

export const SET_NEW_LICENSE_ASSIGNED_TO_YOU =
  "SET_NEW_LICENSE_ASSIGNED_TO_YOU";
export const SET_NEW_LICENSE_ASSIGNED_TO_YOU_SEARCHED =
  "SET_NEW_LICENSE_ASSIGNED_TO_YOU_SEARCHED";
export const SET_NEW_LICENSE_ASSIGNED_TO_OTHERS =
  "SET_NEW_LICENSE_ASSIGNED_TO_OTHERS";
export const SET_NEW_LICENSE_ASSIGNED_TO_OTHERS_SEARCHED =
  "SET_NEW_LICENSE_ASSIGNED_TO_OTHERS_SEARCHED";

export const SET_NEW_LICENSE_APPROVED =
  "SET_NEW_LICENSE_APPROVED";
export const SET_NEW_LICENSE_APPROVED_SEARCHED =
  "SET_NEW_LICENSE_APPROVED_SEARCHED";
export const SET_NEW_LICENSE_ALL_APPROVED =
  "SET_NEW_LICENSE_ALL_APPROVED";
export const SET_NEW_LICENSE_ALL_APPROVED_SEARCHED =
  "SET_NEW_LICENSE_ALL_APPROVED_SEARCHED";

export const SET_NEW_LICENSE_DECLINED =
  "SET_NEW_LICENSE_DECLINED";
export const SET_NEW_LICENSE_DECLINED_SEARCHED =
  "SET_NEW_LICENSE_DECLINED_SEARCHED";
export const SET_NEW_LICENSE_ALL_DECLINED =
  "SET_NEW_LICENSE_ALL_DECLINED";
export const SET_NEW_LICENSE_ALL_DECLINED_SEARCHED =
  "SET_NEW_LICENSE_ALL_DECLINED_SEARCHED";

export const SET_NEW_LICENSE_UNDER_SUPERVISION =
  "SET_NEW_LICENSE_UNDER_SUPERVISION";
export const SET_NEW_LICENSE_UNDER_SUPERVISION_SEARCHED =
  "SET_NEW_LICENSE_UNDER_SUPERVISION_SEARCHED";
export const SET_NEW_LICENSE_OTHERS_UNDER_SUPERVISION =
  "SET_NEW_LICENSE_OTHERS_UNDER_SUPERVISION";
export const SET_NEW_LICENSE_OTHERS_UNDER_SUPERVISION_SEARCHED =
  "SET_NEW_LICENSE_OTHERS_UNDER_SUPERVISION_SEARCHED";

export const SET_NEW_LICENSE_APPROVED_PAYMENT =
  "SET_NEW_LICENSE_APPROVED_PAYMENT";
export const SET_NEW_LICENSE_APPROVED_PAYMENT_SEARCHED =
  "SET_NEW_LICENSE_APPROVED_PAYMENT_SEARCHED";
export const SET_NEW_LICENSE_OTHERS_APPROVED_PAYMENT =
  "SET_NEW_LICENSE_OTHERS_APPROVED_PAYMENT";
export const SET_NEW_LICENSE_OTHERS_APPROVED_PAYMENT_SEARCHED =
  "SET_NEW_LICENSE_OTHERS_APPROVED_PAYMENT_SEARCHED";

export const SET_NEW_LICENSE_DECLINED_PAYMENT =
  "SET_NEW_LICENSE_DECLINED_PAYMENT";
export const SET_NEW_LICENSE_DECLINED_PAYMENT_SEARCHED =
  "SET_NEW_LICENSE_DECLINED_PAYMENT_SEARCHED";
export const SET_NEW_LICENSE_OTHERS_DECLINED_PAYMENT =
  "SET_NEW_LICENSE_OTHERS_DECLINED_PAYMENT";
export const SET_NEW_LICENSE_OTHERS_DECLINED_PAYMENT_SEARCHED =
  "SET_NEW_LICENSE_OTHERS_DECLINED_PAYMENT_SEARCHED";

export const SET_NEW_LICENSE_ON_REVIEW =
  "SET_NEW_LICENSE_ON_REVIEW";
export const SET_NEW_LICENSE_ON_REVIEW_SEARCHED =
  "SET_NEW_LICENSE_ON_REVIEW_SEARCHED";
export const SET_NEW_LICENSE_OTHERS_ON_REVIEW =
  "SET_NEW_LICENSE_OTHERS_ON_REVIEW";
export const SET_NEW_LICENSE_OTHERS_ON_REVIEW_SEARCHED =
  "SET_NEW_LICENSE_OTHERS_ON_REVIEW_SEARCHED";

export const SET_NEW_LICENSE_RE_EVALUATE =
  "SET_NEW_LICENSE_RE_EVALUATE";
export const SET_NEW_LICENSE_RE_EVALUATE_SEARCHED =
  "SET_NEW_LICENSE_RE_EVALUATE_SEARCHED";
export const SET_NEW_LICENSE_OTHERS_RE_EVALUATE =
  "SET_NEW_LICENSE_OTHERS_RE_EVALUATE";
export const SET_NEW_LICENSE_OTHERS_RE_EVALUATE_SEARCHED =
  "SET_NEW_LICENSE_OTHERS_RE_EVALUATE_SEARCHED";

export const SET_NEW_LICENSE_CONFIRMED =
  "SET_NEW_LICENSE_CONFIRMED";
export const SET_NEW_LICENSE_CONFIRMED_SEARCHED =
  "SET_NEW_LICENSE_CONFIRMED_SEARCHED";
export const SET_NEW_LICENSE_OTHERS_CONFIRMED =
  "SET_NEW_LICENSE_OTHERS_CONFIRMED";
export const SET_NEW_LICENSE_OTHERS_CONFIRMED_SEARCHED =
  "SET_NEW_LICENSE_OTHERS_CONFIRMED_SEARCHED";

export const SET_NEW_LICENSE_RETURNED_TO_ME =
  "SET_NEW_LICENSE_RETURNED_TO_ME";
export const SET_NEW_LICENSE_RETURNED_TO_ME_SEARCHED =
  "SET_NEW_LICENSE_RETURNED_TO_ME_SEARCHED";
export const SET_NEW_LICENSE_RETURNED_TO_OTHERS =
  "SET_NEW_LICENSE_RETURNED_TO_OTHERS";
export const SET_NEW_LICENSE_RETURNED_TO_OTHERS_SEARCHED =
  "SET_NEW_LICENSE_RETURNED_TO_OTHERS_SEARCHED";

export const SET_NEW_LICENSE_PENDING_PAYMENT =
  "SET_NEW_LICENSE_PENDING_PAYMENT";
export const SET_NEW_LICENSE_PENDING_PAYMENT_SEARCHED = 
  "SET_NEW_LICENSE_PENDING_PAYMENT_SEARCHED";
export const SET_NEW_LICENSE_OTHERS_PENDING_PAYMENT = 
  "SET_NEW_LICENSE_OTHERS_PENDING_PAYMENT";
export const SET_NEW_LICENSE_OTHERS_PENDING_PAYMENT_SEARCHED =
  "SET_NEW_LICENSE_OTHERS_PENDING_PAYMENT_SEARCHED";

export const SET_NEW_LICENSE_LICENSED =
  "SET_NEW_LICENSE_LICENSED";
export const SET_NEW_LICENSE_LICENSED_SEARCHED = 
  "SET_NEW_LICENSE_LICENSED_SEARCHED";
export const SET_NEW_LICENSE_OTHERS_LICENSED = 
  "SET_NEW_LICENSE_OTHERS_LICENSED";
export const SET_NEW_LICENSE_OTHERS_LICENSED_SEARCHED =
  "SET_NEW_LICENSE_OTHERS_LICENSED_SEARCHED";

export const SET_NEW_LICENSE_ALL_LICENSED =
  "SET_NEW_LICENSE_ALL_LICENSED";
export const SET_NEW_LICENSE_ALL_LICENSED_SEARCHED = 
  "SET_NEW_LICENSE_ALL_LICENSED_SEARCHED"; 

export const SET_NEW_LICENSE_RE_APPLY =
  "SET_NEW_LICENSE_RE_APPLY";
export const SET_NEW_LICENSE_RE_APPLY_SEARCHED = 
  "SET_NEW_LICENSE_RE_APPLY_SEARCHED";
export const SET_NEW_LICENSE_OTHERS_RE_APPLY = 
  "SET_NEW_LICENSE_OTHERS_RE_APPLY";
export const SET_NEW_LICENSE_OTHERS_RE_APPLY_SEARCHED =
  "SET_NEW_LICENSE_OTHERS_RE_APPLY_SEARCHED";

export const SET_NEW_LICENSE_DECLINE_CONFIRMED =
  "SET_NEW_LICENSE_DECLINE_CONFIRMED";
export const SET_NEW_LICENSE_DECLINE_CONFIRMED_SEARCHED = 
  "SET_NEW_LICENSE_DECLINE_CONFIRMED_SEARCHED";
export const SET_NEW_LICENSE_OTHERS_DECLINE_CONFIRMED = 
  "SET_NEW_LICENSE_OTHERS_DECLINE_CONFIRMED";
export const SET_NEW_LICENSE_OTHERS_DECLINE_CONFIRMED_SEARCHED =
  "SET_NEW_LICENSE_OTHERS_DECLINE_CONFIRMED_SEARCHED";

export const SET_NEW_LICENSE_UNDER_SUPER_VISION_CONFIRMED =
  "SET_NEW_LICENSE_UNDER_SUPER_VISION_CONFIRMED";
export const SET_NEW_LICENSE_UNDER_SUPER_VISION_CONFIRMED_SEARCHED = 
  "SET_NEW_LICENSE_UNDER_SUPER_VISION_CONFIRMED_SEARCHED";
export const SET_NEW_LICENSE_OTHERS_UNDER_SUPER_VISION_CONFIRMED = 
  "SET_NEW_LICENSE_OTHERS_UNDER_SUPER_VISION_CONFIRMED";
export const SET_NEW_LICENSE_OTHERS_UNDER_SUPER_VISION_CONFIRMED_SEARCHED =
  "SET_NEW_LICENSE_OTHERS_UNDER_SUPER_VISION_CONFIRMED_SEARCHED";

export const SET_NEW_LICENSE_FOR_SPECIFIC_USER =
  "SET_NEW_LICENSE_FOR_SPECIFIC_USER";
export const SET_NEW_LICENSE_FOR_SPECIFIC_USER_SEARCHED = 
  "SET_NEW_LICENSE_FOR_SPECIFIC_USER_SEARCHED";
export default {
  SET_NEW_LICENSE_UNASSIGNED,
  SET_NEW_LICENSE_UNASSIGNED_SEARCHED,

  SET_NEW_LICENSE_UNFINISHED,
  SET_NEW_LICENSE_UNFINISHED_SEARCHED,
  SET_NEW_LICENSE_OTHERS_UNFINISHED,
  SET_NEW_LICENSE_OTHERS_UNFINISHED_SEARCHED,

  SET_NEW_LICENSE_ASSIGNED_TO_YOU,
  SET_NEW_LICENSE_ASSIGNED_TO_YOU_SEARCHED,
  SET_NEW_LICENSE_ASSIGNED_TO_OTHERS,
  SET_NEW_LICENSE_ASSIGNED_TO_OTHERS_SEARCHED,

  SET_NEW_LICENSE_APPROVED,
  SET_NEW_LICENSE_APPROVED_SEARCHED,
  SET_NEW_LICENSE_ALL_APPROVED,
  SET_NEW_LICENSE_ALL_APPROVED_SEARCHED,

  SET_NEW_LICENSE_DECLINED,
  SET_NEW_LICENSE_DECLINED_SEARCHED,
  SET_NEW_LICENSE_ALL_DECLINED,
  SET_NEW_LICENSE_ALL_DECLINED_SEARCHED,

  SET_NEW_LICENSE_UNDER_SUPERVISION,
  SET_NEW_LICENSE_UNDER_SUPERVISION_SEARCHED,
  SET_NEW_LICENSE_OTHERS_UNDER_SUPERVISION,
  SET_NEW_LICENSE_OTHERS_UNDER_SUPERVISION_SEARCHED,

  SET_NEW_LICENSE_APPROVED_PAYMENT,
  SET_NEW_LICENSE_APPROVED_PAYMENT_SEARCHED,
  SET_NEW_LICENSE_OTHERS_APPROVED_PAYMENT,
  SET_NEW_LICENSE_OTHERS_APPROVED_PAYMENT_SEARCHED,

  SET_NEW_LICENSE_DECLINED_PAYMENT,
  SET_NEW_LICENSE_DECLINED_PAYMENT_SEARCHED,
  SET_NEW_LICENSE_OTHERS_DECLINED_PAYMENT,
  SET_NEW_LICENSE_OTHERS_DECLINED_PAYMENT_SEARCHED,

  SET_NEW_LICENSE_ON_REVIEW,
  SET_NEW_LICENSE_ON_REVIEW_SEARCHED,
  SET_NEW_LICENSE_OTHERS_ON_REVIEW,
  SET_NEW_LICENSE_OTHERS_ON_REVIEW_SEARCHED,

  SET_NEW_LICENSE_RE_EVALUATE,
  SET_NEW_LICENSE_RE_EVALUATE_SEARCHED,
  SET_NEW_LICENSE_OTHERS_RE_EVALUATE,
  SET_NEW_LICENSE_OTHERS_RE_EVALUATE_SEARCHED,

  SET_NEW_LICENSE_CONFIRMED,
  SET_NEW_LICENSE_CONFIRMED_SEARCHED,
  SET_NEW_LICENSE_OTHERS_CONFIRMED,
  SET_NEW_LICENSE_OTHERS_CONFIRMED_SEARCHED,


  SET_NEW_LICENSE_RETURNED_TO_ME,
  SET_NEW_LICENSE_RETURNED_TO_ME_SEARCHED,
  SET_NEW_LICENSE_RETURNED_TO_OTHERS,
  SET_NEW_LICENSE_RETURNED_TO_OTHERS_SEARCHED,

  SET_NEW_LICENSE_PENDING_PAYMENT,
  SET_NEW_LICENSE_PENDING_PAYMENT_SEARCHED,
  SET_NEW_LICENSE_OTHERS_PENDING_PAYMENT,
  SET_NEW_LICENSE_OTHERS_PENDING_PAYMENT_SEARCHED,

  SET_NEW_LICENSE_LICENSED,
  SET_NEW_LICENSE_LICENSED_SEARCHED,
  SET_NEW_LICENSE_OTHERS_LICENSED,
  SET_NEW_LICENSE_OTHERS_LICENSED_SEARCHED,

  SET_NEW_LICENSE_ALL_LICENSED,
  SET_NEW_LICENSE_ALL_LICENSED_SEARCHED,

  SET_NEW_LICENSE_RE_APPLY,
  SET_NEW_LICENSE_RE_APPLY_SEARCHED,
  SET_NEW_LICENSE_OTHERS_RE_APPLY,
  SET_NEW_LICENSE_OTHERS_RE_APPLY_SEARCHED,

  SET_NEW_LICENSE_DECLINE_CONFIRMED,
  SET_NEW_LICENSE_DECLINE_CONFIRMED_SEARCHED,
  SET_NEW_LICENSE_OTHERS_DECLINE_CONFIRMED,
  SET_NEW_LICENSE_OTHERS_DECLINE_CONFIRMED_SEARCHED,

  SET_NEW_LICENSE_UNDER_SUPER_VISION_CONFIRMED,
  SET_NEW_LICENSE_UNDER_SUPER_VISION_CONFIRMED_SEARCHED,
  SET_NEW_LICENSE_OTHERS_UNDER_SUPER_VISION_CONFIRMED,
  SET_NEW_LICENSE_OTHERS_UNDER_SUPER_VISION_CONFIRMED_SEARCHED,

  SET_NEW_LICENSE_FOR_SPECIFIC_USER,
  SET_NEW_LICENSE_FOR_SPECIFIC_USER_SEARCHED,
};
